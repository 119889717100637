@import "function", "variables", "common";

/*  
    WRAPPER ******************************************************
*/
.navWrapper {
  // margin-top: 85px;
  // margin-top: 40px;
}
.wrapper {
  padding: 30px 15px;
  @media (max-width: 600px) {
    padding: 15px;
  }
}
.wrapper2 {
  padding-top: 45px;
  // @media (max-width: 600px) {
  //   padding-top: 30px;
  // }
}

/* 
    HOME PAGE******************************************************************
    
*/
// HEADER-----
.circle-arrow-text {
  position: relative;
  .arrow-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
  }
}
.grab-sim {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  padding: 10px;
  border-radius: 12px;
  background-color: #272727;
  .text {
    font-size: 13px;
    color: #fff;
  }
}

.home-slider-container {
  height: 100%;
  .swiper {
    width: 100%;
    height: 100%;
  }
  .swiper-pagination-bullet {
    width: 10px;
    height: 5px;
    border-radius: 4px;
    background-color: #fff;
    opacity: 0.5;
  }
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 25px;
    opacity: 1;
  }
}
.home-slider-item {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  .slick-track,
  .slick-slide {
    display: flex;
  }
  .corner-bg {
    position: absolute;
    top: 0;
    right: 0;
    width: 65%;
    height: 100%;
    z-index: 1;
  }
  .human-bg {
    position: relative;
    bottom: 0;
    margin-top: auto;
    left: 0;
    width: 75%;
    height: 100%;
    z-index: 2;
  }
}

.make-connection {
  position: absolute;
  z-index: 5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
  padding: 10px;
  right: 30px;
  top: 50%;
  border-radius: 12px;
  background-color: #ffffff;
  .text {
    font-size: 13px;
    color: rgb(0, 0, 0);
  }
}
.new-alert {
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: $primary;
  z-index: 5;
  color: #fff;
  bottom: 10%;
  right: 20%;
  @extend .p-center;
  span {
    transform: rotate(45deg);
    font-family: "Caveat";
  }
  @media (max-width: 600px) {
    bottom: 2%;
  }
}
// WHY CHOOSE US--
.why-choose-us {
  position: relative;
  height: 450px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 15px;
  @media (max-width: 600px) {
    height: auto;
  }
  .africa-map-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    img {
      position: absolute;
      top: 20px;
      left: 50%;
      width: auto;
      transform: translateX(-50%);
      height: 430px;
      z-index: -1;
      @media (max-width: 600px) {
        opacity: 0.6;
      }
    }
  }
  .choose-us-title {
    position: absolute;
    top: 100px;
    left: 50%;
    font-size: 40px;
    transform: translateX(-50%);
    font-family: "DM Serif Display";
    color: $primaryVar;
    @media (max-width: 600px) {
      position: static;
      text-align: center;
      transform: none;
    }
  }
  .reason-cont {
    display: flex;
    justify-content: space-between;
    position: relative;
    // z-index: 2;
    @media (max-width: 600px) {
      flex-direction: column;
    }
  }
  .reasons-1 {
    margin: 0 10%;
  }
  .reasons-3 {
    margin: 0 10%;
  }
  .reason {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .choose-us-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    @media (max-width: 600px) {
      flex-direction: column;
    }
    p {
      font-size: 14px;
      color: $grey3;
    }
  }
}

/* 
  FOOTER ****************************************************************************
*/
.footer {
  background-color: #545454;
  padding-top: 60px;
  background-image: url(../assets/img/static/bg/footerWorld.png);
  background-position: center;
  background-repeat: no-repeat;
  block-size: cover;
}

//  FOOTER 2
.footer2-bg {
  background-color: #333333;
  background-image: url(../assets/img/static/bg/worldMapWhite.png);
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  padding-left: 100px;
  padding-top: 80px;
  @media (max-width: 480px) {
    padding-left: 30px;
    padding-top: 20px;
  }
}
// LOGO NAVBAR
.logo-navbar {
  width: 100%;
  background-color: transparent;
  // position: fixed;
  top: 0;
  left: 0;
  img {
    width: 85px;
  }
}

/* 
pick-product-container******************************************************
*/
.pick-mobile-internet-page,
.pick-home-internet-page {
  // background: linear-gradient(to right, #000 70%, $primary 70% 100%);
  min-height: 100vh;
}
/* 
  BUILD YOUR PLAN*******************************************************************
*/
// .primary-white-bg {
//   background: linear-gradient(45deg, $primary 1% 10%, #fff 20% 80%, $primary);
// }
.build-plan-header {
  .build-title {
    font-size: 60px;
    font-family: "Poppins";
    text-align: center;
    line-height: 0.8;
  }
}
.circle-calculate {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
}
.circle-bar-container {
  padding: 10px;
  border: 1px solid rgba(178, 175, 175, 0.6);
  border-radius: 50%;
  height: 102px;
  position: relative;
  .data-text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.app-card {
  display: flex;
  padding: 12px;
  border: 1px solid #b4b4b4;
  width: 220px;
  border-radius: 8px;
  justify-content: start;
  align-items: center;
  position: relative;
  cursor: pointer;

  .info-des {
    flex: 0 0 auto;
    margin-left: 15px;
    h4 {
      margin-bottom: 0;
      font-size: 18px;
      font-weight: 600;
      line-height: 1;
      font-family: "Poppins", "Raleway", sans-serif;
    }
    span {
      font-size: 12px;
      color: #a09e9e;
    }
  }
}
/* 
  SEARCH BOX ***************************************************************
*/
.white-search-box-container {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: baseline;
  padding: 10px 15px;
  border-radius: 18px;
  .white-search-box-input {
    border: none;
    outline: none;
    padding: 4px;
    margin-left: 12px;
    flex: 1 1 auto;
  }
}
.outline-search-box-container {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: baseline;
  padding: 10px 15px;
  border-radius: 18px;
  border: 1px solid #b9b9b9;
  border-radius: 26px;
  width: 100%;
  .outline-search-box-input {
    border: none;
    outline: none;
    padding: 4px;
    margin-left: 12px;
    flex: 1 1 auto;
    background-color: transparent;
    color: rgba(255, 255, 255, 0.75);
  }
  i {
    color: rgba(255, 255, 255, 0.5);
  }
}
.outline-search-box-container.dark {
  .outline-search-box-input {
    color: rgba(0, 0, 0, 0.75);
  }
  i {
    color: rgba(0, 0, 0, 0.5);
  }
}

/* 
  PRESS RELEASE *****************************
*/
.press-release-header-container {
  background-image: url(../assets/img/static/bg/pressReleaseBg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 300px;
}

.round-search {
  position: relative;
  display: flex;
  width: 100%;
  input {
    flex: 1 0 auto;
    border: 1px solid #d3d3d3;
    border-radius: 20px;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.75);
    padding: 8px 18px;
    &:focus {
      outline: none;
    }
  }
  button {
    border: none;
    padding: 8px 20px;
    background-color: $primary;
    color: #ffff;
    border-radius: 20px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
  }
}

/* 
  HELP VIDEO BANNER************************************************
*/
.help-video-banner {
  background-image: url(../assets/img/dynamic/bg/helpSearchVideo.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  width: 100%;
  min-height: 350px;
  margin-top: 20px;
  .help-video-layout {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background-color: transparent;
  }
  .help-video-play-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .help-video-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
  }
  .help-video-desc {
    padding-left: 20px;
    p {
      border-left: 2px solid $primary;
      color: #fff;
      font-family: "Raleway", "Poppins";
      padding-left: 10px;
      line-height: 1;
    }
  }
  .help-video-thumbs {
    padding-right: 20px;
    display: flex;
  }
}

.top-leaf-pic {
  position: relative;
  &::before {
    background-image: url(../assets/img/static/icon/leaf.png);
    background-repeat: no-repeat;
    background-position: bottom center;
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    content: "";
    background-size: 20px 20px;
  }
  @media (max-width: 480px) {
    &::before {
      display: none;
    }
  }
}

/* 
  BLOG ********************************************************
*/
.blog-item-container {
  position: relative;
  min-height: 150px;
  cursor: pointer;
  .blog-overlay {
    position: absolute;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
  .blog-item-content {
    position: absolute;
    padding: 20px;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
  .blog-item-desc {
    max-width: 300px;
    color: #fff;
    font-family: "Raleway";
    margin-top: 10px;
  }
  .blog-date {
    color: #fff;
  }
  @media (max-width: 480px) {
    .blog-item-desc,
    .blog-date {
      font-size: 12px;
    }
  }
}

/* 
  STEPPER- LINE
*/
.stepper-line {
  position: relative;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1px;
  background-color: #929292;
  z-index: 1;
  margin-left: 15px;
  span {
    padding: 4px;
    position: relative;
    z-index: 2;
    background-color: #fff;
    border: 1px dashed #929292;
    border-radius: 50%;
  }
}
.stepper-line-2 {
  position: absolute;
  top: -24px;
  width: 1px;
  background-color: #929292;
  height: 75px;
  left: 7.5px;
  z-index: 3;
}
.swiper-side-nav-btn {
  padding-left: 40px;
  .swiper-pagination {
    left: -1px;
    top: 0;
    bottom: 0;
    background-color: #fff;
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 38px;
    .custom-bullet-color {
      margin-top: 3px;
    }
    .swiper-pagination-bullet-active {
      background: $primaryVar;
    }
  }
}

.enterprise-nav-item {
  padding: 10px 15px;
  background-color: #fff;
  border: 1.5px solid #cacaca;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 150px;
  color: #bababa;
  transition: 0.4s;
  cursor: pointer;
  .navIcon {
    transition: 0.4s;
  }
  &:hover,
  &.active {
    border-color: transparent;
    background-color: #f9f6d7;
    color: $primary;
    .navIcon {
      transform: rotate(180deg);
    }
  }
}

.send-us-msg-box {
  padding: 20px;
  margin-left: 30px;
  background-color: $primary;
  border-top-left-radius: 20px;
  position: relative;
  .send-us-text {
    text-transform: uppercase;
    margin-bottom: 40px;
    margin-left: 15px;
    position: relative;
    &::after {
      position: absolute;
      content: "";
      left: 0;
      bottom: -10px;
      border-radius: 20px;
      height: 5px;
      width: 100px;
      background-color: $primaryVar;
    }
  }
}
.send-us-msg-box::before {
  content: "";
  position: absolute;
  left: -30px;
  bottom: 0;
  height: calc(100% - 40px);
  background-color: $primaryVar;
  border-top-left-radius: 50px;
  width: 30px;
}

.custom-nav-swiper {
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 10px;
  .swiper-button-prev::after {
    content: "\f104";
    font-weight: 900;
    font-family: "Font Awesome 6 Free";
    font-size: 36px;
    color: rgba(0, 0, 0, 0.65);
    transition: 0.4s;
  }
  .swiper-button-prev {
    left: 0;
    &:hover {
      &::after {
        color: rgba(0, 0, 0, 1);
      }
    }
  }

  .swiper-button-next::after {
    content: "\f105";
    font-weight: 900;
    font-family: "Font Awesome 6 Free";
    font-size: 36px;
    color: rgba(0, 0, 0, 0.65);
    transition: 0.4s;
  }
  .swiper-button-next {
    right: 0;
    &:hover {
      &::after {
        color: rgba(0, 0, 0, 1);
      }
    }
  }
  @media (max-width: 480px) {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
  }
}

.custom-nav-swiper-2 {
  .swiper-button-prev {
    top: unset;
    left: unset;
    bottom: 40px;
    right: 80px;
    color: #fff;
    height: unset;
    &:after {
      font-size: 16px;
      font-weight: 900;
    }
  }
  .swiper-button-next {
    top: unset;
    left: unset;
    bottom: 40px;
    right: 40px;
    color: #fff;
    height: unset;
    &:after {
      font-size: 16px;
      font-weight: 900;
    }
  }
}

.custom-pagination-swiper {
  &.five-G-shop-slider {
    position: unset;
    .swiper-pagination {
      width: fit-content !important;
    }
  }
  &.home-shop-now {
    padding-bottom: 30px;
    .swiper-pagination {
      text-align: right !important;
      .swiper-pagination-bullet-active {
        background-color: $secondary !important;
      }
    }
  }
  &.normal {
    .swiper-pagination {
      position: unset !important;
      padding-top: 30px !important;
      text-align: left;
      .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        &.swiper-pagination-bullet-active {
          background-color: $blackPrimary;
        }
      }
    }
  }
  @media (min-width: 768px) {
    &.deals-weeks-highlights-slider .swiper-pagination {
      display: none;
    }
  }
}

.swiper-pagination {
  overflow: auto;
  max-width: 100%;
  white-space: nowrap;

  /* Hide scrollbar across browsers */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Hide scrollbar for WebKit browsers (Chrome, Safari) */
  &::-webkit-scrollbar {
    display: none; /* WebKit browsers */
  }

  .swiper-pagination-bullet {
    width: 30px;
    height: 10px;
    border-radius: 30px;
    background-color: $grey3;
    flex: 0 0 30px;

    &.swiper-pagination-bullet-active {
      width: 45px;
      flex: 0 0 45px;
      background-color: $primaryVar;
    }
  }
}

/* 
  ODD EVEN BOX**********************************
*/
.odd-even-box {
  padding: 10px;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  .odd-even-box-item {
    flex: 1 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: calc(50% - 4px);
    border-radius: 6px;
    margin-bottom: 8px;
    color: #fff;
    text-align: center;
    padding: 5px 15px;
    p {
      margin-bottom: 0;
    }
  }
  .odd-even-box-item.primary {
    background-color: $primaryVar;
  }
  .odd-even-box-item.secondary {
    background-color: $secondary;
  }
  .odd-even-box-item:nth-child(even) {
    margin-left: 8px;
  }
  .odd-even-box-item.tl {
    border-top-left-radius: 50px;
  }
  .odd-even-box-item.tr {
    border-top-right-radius: 50px;
  }
  .odd-even-box-item.bl {
    border-bottom-left-radius: 50px;
  }
  .odd-even-box-item.br {
    border-bottom-right-radius: 50px;
  }
  @media (max-width: 480px) {
    .odd-even-box-item {
      width: 100% !important;
      margin-left: 0 !important;
      border-radius: 6px !important;
      padding: 5px 7px;
      height: auto !important;
      min-height: 100px;
    }
    .odd-even-box-item.res.primary {
      background-color: $secondary;
    }
    .odd-even-box-item.res.secondary {
      background-color: $primaryVar;
    }
  }
}

.b-b-r {
  position: relative;
  &::after {
    content: "";
    position: absolute;
    bottom: -5px;
    right: 0;
    width: 150px;
    max-width: 100%;
    height: 3px;
    background-color: $primaryVar;
    border-radius: 10px;
  }
}

/* 
  NAVBAR ***********************************************************************
*/
header {
  position: sticky;
  top: 0;
  z-index: 1200;
}
.navbar-container {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  &.transparent,
  &.transparent-bg-only {
    background-color: transparent;
    position: absolute;
  }
  &.dark-bg:not(.transparent) {
    background-color: #545454;
  }
}

.desktop-nav-container {
  padding-top: 4px;
  padding-bottom: 4px;
  background-color: #fff;
  box-shadow: 0 10px 10px -5px rgba(0, 0, 0, 0.1);
}
.transparent .desktop-nav-container,
.transparent-bg-only .desktop-nav-container {
  box-shadow: none;
  background-color: transparent;
}
.dark-bg:not(.transparent) .desktop-nav-container {
  box-shadow: #545454;
  background-color: transparent;
}
.desktop-navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  flex-basis: auto;
  z-index: 1000;
  position: relative;
}
.nav-brand {
  max-width: 160px;
  overflow: hidden;
  img {
    max-width: 100%;
    object-fit: contain;
  }
}
.desktop-nav-items-container {
  display: flex;
  flex-wrap: nowrap;
  /* justify-content: space-around; */
  /* flex: 1 1 auto; */
}
.desk-nav-item {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  color: rgba(0, 0, 0, 0.6);
  transition: all 0.4s ease-in-out;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  &:hover {
    color: $primary;
    .nav-sub-menu {
      display: flex;
    }
    .item-icon svg {
      transform: rotate(180deg);
    }
  }
  .item-name {
    padding: 10px 20px;
    text-decoration: none;
    text-transform: capitalize;
    font-family: $secondaryFont;
    font-weight: 400;
    font-size: 18px;
    color: #000;
    border-radius: 4px;
    display: flex;
    align-items: center;
    .item-icon svg {
      transition: 0.4s ease-in-out;
    }
    &.active {
      // background-color: $primaryVar;
      color: $primaryVar;
    }
  }
  div {
    cursor: pointer !important;
  }
}
.transparent .desk-nav-item {
  color: rgba(255, 255, 255, 0.65);
  &:hover {
    color: $primary;
  }
}
.dark-bg:not(.transparent) .desk-nav-item {
  color: rgba(255, 255, 255, 1);
  &:hover {
    color: $primary;
  }
}
.nav-sub-menu {
  position: absolute;
  top: 100%;
  width: max-content;
  max-width: 700px;
  padding: 25px 38px;
  left: 0;
  z-index: 1001;
  border-radius: 28px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  display: none;
  justify-content: space-between;
  background-color: #fff;
  flex: 1 0 auto;
  overflow-y: auto;
  &.right {
    right: 0;
    left: unset;
  }
  &.show {
    display: flex;
  }
  &.fullWidth {
    width: 100%;
    position: fixed;
    top: 60px;
    z-index: 999;
    max-width: 100%;
  }
  &.mega-menu {
    height: 420px;
  }

  &.mobile {
    position: fixed;
    top: 70px;
    width: 100%;
    max-width: 100%;
    border-radius: 0;
    left: 0;
    flex-direction: column;
    overflow-y: auto;
    height: calc(100vh - 70px);
    box-shadow: none;
  }

  .submenu-link {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    padding: 8px 12px;
    // margin-bottom: 15px;
    border-radius: 8px;
    .nav-submenu-link-header {
      font-size: 18px;
      color: #000;
    }
    .nav-submenu-link-des {
      font-size: 12px;
      flex: 1 0 auto;
      display: block;
      color: rgba(0, 0, 0, 0.6);
    }
    &:hover,
    &.active {
      background-color: #faedda;
      .nav-submenu-link-header,
      .nav-submenu-link-des {
        color: $primary;
      }
    }
  }
}
// MOBILE
.mobile-nav-wrapper {
  width: 100%;
  position: relative;
  .mobile-nav-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding: 15px;
    background-color: #fff;
    position: relative;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 100%;
    &:not(.collapsed) {
      padding: 16px;
    }
    &.collapsed {
      position: relative;
      // box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    }
  }
}
.transparent .mobile-nav-wrapper .mobile-nav-container.collapsed,
.transparent-bg-only .mobile-nav-wrapper .mobile-nav-container.collapsed {
  box-shadow: none;
  background-color: transparent;
}
.mobile-menu-container {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 80px;
  left: 0;
  width: 100%;
  padding-top: 30px;
  background-color: #fff;
  height: calc(100dvh - 80px);
  z-index: 1002;
  min-height: calc(100dvh - 80px);

  &.collapsed {
    display: none;
  }
}
.mobile-nav-item {
  position: relative;
  border-bottom: 1px solid rgba(171, 171, 171, 0.6);
  .mobile-nav-link {
    padding: 15px;
    background-color: #fff;
    color: #000;
    text-align: center;
    display: block;
    transition: all 0.4s;
    cursor: pointer;
    &:hover,
    &.active {
      color: $primary;
    }
  }
}
.enterprise-submenu-link {
  color: #000;
  cursor: pointer;
  text-transform: uppercase;
  transition: 0.4s;
  line-height: 1.5;
  font-size: 1rem;
  text-align: center;
  font-family: Poppins;
  display: block;
  width: 100%;
  padding: 5px 0;
  &:hover {
    color: $primary;
  }
  &.active {
    color: $primary;
  }
}

.hero-indicator {
  display: flex;
  justify-content: center;
  span {
    display: inline-block;
    width: 40px;
    border-radius: 8px;
    height: 4px;
    background-color: #c6c6c6;
    margin-right: 4px;
    &.active {
      width: 80px;
      background-color: $primary;
    }
  }
}

.card-22 {
  height: 380px;
  width: 225px;
  border-radius: 20px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 25px;
  margin-top: 10px;
  &.down {
    margin-top: 45px;
  }
  .b-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
    border-radius: 20px;
    z-index: 1;
  }
  p {
    text-align: center;
    color: #fff;
    position: relative;
    z-index: 2;
  }
}

.custom-faw-swiper {
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    text-align: start;
  }
  .swiper-pagination-bullet {
    width: 20px;
    height: 5px;
    border-radius: 4px;
    background-color: $primaryVar;
    opacity: 0.5;
  }
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 40px;
    opacity: 1;
  }
}

.big-quot::before {
  content: "\275D";
  display: inline-block;
  font-size: 25px;
  margin-right: 5px;
  line-height: 0.8;
}
.big-quot::after {
  content: "\275E";
  display: inline-block;
  font-size: 25px;
  margin-left: 5px;
  line-height: 0.8;
}
.custom-faw-swiper.custom-nav-swiper {
  .swiper-pagination-bullets.swiper-pagination-horizontal {
    text-align: center;
  }
}

.hbg-btn {
  color: rgb(36, 36, 36);
  position: relative;
  line-height: 1;
  word-spacing: 1px;
  font-family: Raleway, serif;
  padding: 8px 12px;
  z-index: 2;
  .btn-arw {
    margin-left: 8px;
  }
}
.hbg-btn:hover {
  color: rgb(36, 36, 36);
  &::before {
    width: 100%;
  }
}
.hbg-btn::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 70%;
  background-color: $primaryVar;
  z-index: -1;
  transition: 0.4s;
}

.rowBg {
  width: 100%;
  max-width: 150px;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  background-image: linear-gradient($primaryVar, #fff);
  border-top-right-radius: 100px;
  border-bottom-left-radius: 100px;
}

.text-big-1 {
  background: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.1));
}
.text-big-2 {
  background: linear-gradient(#ff0000, rgba(255, 0, 0, 0.1));
}
.text-big-3 {
  background: linear-gradient(#464646, rgba(0, 0, 0, 1));
}
.big-linear-text {
  font-family: Raleway, sans-serif;
  font-size: 10vw;
  font-weight: 800;
  text-align: center;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
@media (max-width: 480px) {
  .big-linear-text {
    font-size: 50px;
  }
}
.circle-bg {
  position: relative;
  overflow: hidden;
}

.bg-circle-right {
  position: absolute;
  right: 0;
  top: 20px;
  z-index: -1;
  max-width: 200px;
}
.bg-circle-left {
  position: absolute;
  left: 0;
  bottom: -30px;
  max-width: 200px;
  z-index: -1;
}

.footer-container {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.mfd-mobile {
  margin-bottom: -200px;
}
.mfd-mobile1 {
  position: absolute;
  top: -50px;
  right: 0;
  width: 35%;
  max-width: 250px;
  overflow: hidden;
  img {
    width: 100%;
    vertical-align: baseline;
  }
}
.mfd-mobile2 {
  position: absolute;
  top: -40px;
  left: 0;
  width: 35%;
  max-width: 250px;
  overflow: hidden;
  img {
    width: 100%;
    vertical-align: baseline;
  }
}
